.el-dialog .el-dialog__body {
  padding: 10px !important;
  background-color: #fff !important; }

.el-table {
  font-size: var(--rootFontSize); }
  .el-table .el-table__cell .cell {
    line-height: inherit; }

.el-form-item__label {
  word-break: normal; }
