.con[data-v-57d78e5c] {
  position: relative;
}
.con .input-text-hidden[data-v-57d78e5c] .el-input__inner {
    color: #f5f7fa00 !important;
}
.con[data-v-57d78e5c] .el-input-group__append {
    padding: 0;
}
.lang-link[data-v-57d78e5c] {
  position: absolute;
  top: 0;
  left: 8px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lang-link[data-v-57d78e5c]:hover {
    color: blue;
    text-decoration: underline;
}
.lang-link.small-lang-link[data-v-57d78e5c] {
  font-size: 14px;
  top: 4px;
}
.button-alive[data-v-57d78e5c] {
  cursor: pointer;
}
.button-no-alive[data-v-57d78e5c] {
  cursor: pointer;
}
.button-no-alive .el-icon-setting[data-v-57d78e5c]:hover {
    color: #5cbdff;
}
