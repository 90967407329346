#app {
  font-family: Microsoft Yahei, \\5fae\8f6f\96c5\9ed1, PingFang SC, Simsun, STHeiti, -apple-system, BlinkMacSystemFont, Helvetica Neue, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.el-image-viewer__wrapper {
  width: 76%;
  height: 90%;
  left: 12%;
  top: 5%;
  z-index: 3000 !important;
}
.tags-list .tag-item:nth-child(1) {
  display: none;
}
.tox-tinymce-aux {
  z-index: 2300 !important;
}
.tox-tinymce {
  margin: auto;
}
.el-card {
  overflow-y: auto;
}
.wrap-container {
  height: 100%;
}
.wrap-container .wrap-main {
    padding: 10px;
}
