.page-mask[data-v-ed3871ae] {
  position: absolute;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
}
.page-mask .page-loading[data-v-ed3871ae] {
    top: 50%;
    left: 50%;
    position: absolute;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    z-index: 100;
    width: 50px;
    height: 60px;
    font-size: var(--rootFontSize4);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.page-mask .page-loading div[data-v-ed3871ae] {
      /* background-color: #FE3C71; */
      background-color: #008df7;
      height: 100%;
      width: 6px;
      margin-right: 5px;
      -webkit-animation: stretchDelay-data-v-ed3871ae 3s infinite ease-in-out;
      animation: stretchDelay-data-v-ed3871ae 3s infinite ease-in-out;
}
.page-mask .page-loading .rect2[data-v-ed3871ae] {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s;
}
.page-mask .page-loading .rect3[data-v-ed3871ae] {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
}
.page-mask .page-loading .rect4[data-v-ed3871ae] {
      -webkit-animation-delay: -0.9s;
      animation-delay: -0.9s;
}
.page-mask .page-loading .rect5[data-v-ed3871ae] {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
}
@-webkit-keyframes stretchDelay-data-v-ed3871ae {
0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
}
20% {
    -webkit-transform: scaleY(1);
}
}
@keyframes stretchDelay-data-v-ed3871ae {
0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
}
20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
}
}
