:root {
  --mainThemeColor: #0270c1;
  --secondThemeColor: #008df7;
  --threeThemeColor: #0267b2;
  --fourThemeColor: #7494a9;
  --fiveThemeColor: #f56c6c;
  --primaryBgColor: #0270c1;
  --successBgColor: #f0f9eb;
  --successTextColor: #67c23a;
  --rootFontSize: 16px;
  --rootFontSize1: 18px;
  --rootFontSize2: 20px;
  --rootFontSize3: 28px;
  --rootFontSize4: 12px; }

@media (max-width: 767px) {
  :root {
    --rootFontSize: 12px;
    --rootFontSize1: 18px;
    --rootFontSize2: 20px;
    --rootFontSize3: 28px;
    --rootFontSize4: 12px; } }

@media (min-width: 768px) and (max-width: 979px) {
  :root {
    --rootFontSize: 12px;
    --rootFontSize1: 18px;
    --rootFontSize2: 20px;
    --rootFontSize3: 28px;
    --rootFontSize4: 12px; } }

@media (max-width: 979px) {
  :root {
    --rootFontSize: 16px;
    --rootFontSize1: 18px;
    --rootFontSize2: 20px;
    --rootFontSize3: 28px;
    --rootFontSize4: 12px; } }

@media (min-width: 980px) {
  :root {
    --rootFontSize: 12px;
    --rootFontSize1: 18px;
    --rootFontSize2: 20px;
    --rootFontSize3: 28px;
    --rootFontSize4: 12px; } }

@media (min-width: 1200px) {
  :root {
    --rootFontSize: 12px;
    --rootFontSize1: 18px;
    --rootFontSize2: 20px;
    --rootFontSize3: 28px;
    --rootFontSize4: 12px; } }

@media (min-width: 1600px) {
  :root {
    --rootFontSize: 16px;
    --rootFontSize1: 18px;
    --rootFontSize2: 20px;
    --rootFontSize3: 28px;
    --rootFontSize4: 12px; } }
