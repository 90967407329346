@charset "UTF-8";
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500; }

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
  font-size: inherit; }

body {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Microsoft YaHei";
  line-height: 1.5; }

html,
body {
  height: 100%;
  background: #ebeff2; }

fieldset,
img {
  border: 0; }

ol,
ul {
  list-style: none; }

em {
  font-style: normal; }

input,
button,
select,
textarea {
  outline: none; }

textarea {
  resize: none; }

p {
  text-align: justify;
  text-justify: distribute;
  word-wrap: break-word; }

i {
  display: inline-block; }

address,
cite,
code,
em,
th {
  font-weight: normal;
  font-style: normal; }

a {
  color: inherit;
  text-decoration: none;
  display: inline-block; }

a:hover,
a:active,
a:focus {
  text-decoration: underline; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.hidden {
  display: none; }

.el-step {
  display: inline-block !important; }

.el-menu--popup {
  background-color: #ffffff !important; }
  .el-menu--popup .el-menu-item {
    font-size: var(--rootFontSize) !important; }

.el-tree .el-tree-node__content .el-tree-node__label {
  font-size: var(--rootFontSize) !important; }

.el-table th {
  color: #fff;
  background-color: var(--mainThemeColor) !important; }

.el-table thead.is-group th {
  color: #fff;
  background-color: var(--mainThemeColor) !important; }

.text_box span {
  color: var(--mainThemeColor) !important; }

.el-form-item__label {
  font-size: var(--rootFontSize) !important; }

.el-form-item__content {
  font-size: var(--rootFontSize) !important; }

.el-tag {
  font-size: var(--rootFontSize) !important; }

.el-dropdown-menu__item {
  font-size: var(--rootFontSize) !important; }

.el-button {
  font-size: var(--rootFontSize) !important; }

.el-radio-button__inner {
  font-size: var(--rootFontSize) !important; }

.el-radio__label {
  font-size: var(--rootFontSize) !important; }

.el-pagination span:not([class*="suffix"]) {
  font-size: var(--rootFontSize) !important; }

.tree-span {
  font-size: var(--rootFontSize) !important; }

.el-tabs__item {
  font-size: var(--rootFontSize) !important; }

.el-header {
  padding: 0px !important; }

.el-dropdown-menu__item {
  line-height: 28px !important; }

.el-menu--horizontal {
  border-bottom: none !important; }

.el-menu {
  border-right: none !important; }

.el-menu--display,
.el-menu--display + .el-submenu__icon-arrow {
  display: none !important; }

.el-tag {
  height: 28px !important;
  line-height: 26px !important; }

.el-button--text {
  padding: 0 !important; }

.el-table {
  margin-bottom: 18px; }
  .el-table .el-table__row td {
    padding: 5px 0px !important; }
  .el-table th {
    padding: 5px 0px !important; }

/* 弹窗样式修改 */
.el-dialog {
  max-height: calc(100% - 70px);
  max-width: calc(100% - 30px);
  position: relative !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background: url(../../static/img/dialog_bj.c10727a5.png) no-repeat bottom #f7f7f7 !important;
  background-size: 100% auto !important;
  margin: 0 auto; }
  .el-dialog .el-dialog__header {
    background-color: var(--mainThemeColor) !important;
    color: #fff;
    line-height: 50px;
    text-align: left;
    text-indent: 35px;
    font-size: 18px;
    padding: 0;
    font-weight: bolder;
    letter-spacing: 1px; }
    .el-dialog .el-dialog__header .el-dialog__title {
      color: #fff !important; }
  .el-dialog .el-dialog__headerbtn {
    position: absolute;
    top: 15px;
    right: 15px; }
    .el-dialog .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
      font-size: 20px;
      font-weight: bolder; }
    .el-dialog .el-dialog__headerbtn .el-dialog__close:hover {
      color: #fff; }
  .el-dialog .el-dialog__body {
    padding: 25px 25px 0 !important;
    overflow: auto; }
  .el-dialog .el-dialog__footer {
    margin-top: auto; }

/* loading动画提示字居中 */
.el-loading-spinner .el-loading-text {
  text-align: center !important; }

.fullscreenIcon {
  cursor: pointer;
  position: absolute;
  right: 50px;
  top: 0;
  bottom: 0;
  margin: auto; }

/*确认信息弹框样式*/
.el-message-box__wrapper .el-message-box {
  border: none;
  background: #ffffff; }
  .el-message-box__wrapper .el-message-box .el-message-box__header .el-message-box__title span {
    color: #499ddb;
    font-size: 25px;
    font-weight: bolder; }
  .el-message-box__wrapper .el-message-box .el-message-box__header .el-message-box__headerbtn i {
    color: #499ddb;
    font-weight: bolder;
    font-size: 20px; }
  .el-message-box__wrapper .el-message-box .el-message-box__content .el-message-box__status {
    display: none; }
  .el-message-box__wrapper .el-message-box .el-message-box__content .el-message-box__message p {
    font-weight: bolder;
    font-size: 20px; }
  .el-message-box__wrapper .el-message-box .el-message-box__btns {
    float: right;
    margin-bottom: 10px; }
    .el-message-box__wrapper .el-message-box .el-message-box__btns button {
      border: 1px solid #499ddb;
      border-radius: 4px;
      font-size: 16px; }
    .el-message-box__wrapper .el-message-box .el-message-box__btns button:first-child {
      color: #499ddb; }
    .el-message-box__wrapper .el-message-box .el-message-box__btns button:first-child:hover {
      color: #499ddb;
      background: #ffffff; }
    .el-message-box__wrapper .el-message-box .el-message-box__btns button:last-child {
      color: #ffffff;
      background: #499ddb; }

.el-card.is-always-shadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.el-tooltip__popper {
  font-size: 14px;
  max-width: 40% !important;
  line-height: 2em !important; }

.el-input.is-disabled .el-input__inner {
  color: black !important; }

.el-textarea.is-disabled .el-textarea__inner {
  color: black !important; }

.mgb-0 {
  margin-bottom: 0 !important; }

.mgb-10 {
  margin-bottom: 10px !important; }

.mgl-0 {
  margin-left: 0 !important; }

.mgl-10 {
  margin-left: 10px !important; }

.mgt-0 {
  margin-top: 0 !important; }

.mgt-10 {
  margin-top: 10px !important; }

.mgr-0 {
  margin-right: 0 !important; }

.mgr-10 {
  margin-right: 10px !important; }

.hg-100-percent {
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .hg-100-percent .el-card__body {
    height: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }

.text_box {
  width: 100%;
  height: 30px;
  line-height: 30px !important;
  font-size: 16px;
  font-weight: 600; }
  .text_box span {
    color: var(--mainThemeColor); }
  .text_box .el-button span {
    color: #ffffff; }

.text_box_line {
  width: 100%;
  height: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #7f7f7f; }

/*出现滚动条*/
.overflow-scroll {
  overflow: scroll; }

.overflow-scroll-y {
  overflow-y: scroll;
  overflow-x: hidden; }

.overflow-scroll-x {
  overflow-x: scroll;
  overflow-y: hidden; }

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 6px;
  /*滚动条宽度*/
  height: 8px;
  /*滚动条高度*/ }

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  width: 6px;
  border-radius: 10px;
  /*滚动条的背景区域的圆角*/ }

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  /*滚动条的圆角*/
  background-color: var(--secondThemeColor); }

.sortable-ghost {
  opacity: 0.8;
  color: #fff !important;
  background: #42b983 !important; }

.page-container {
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .page-container .page-search-bar {
    width: 100%;
    height: 70px;
    background: #fff;
    padding: 15px 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px; }
  .page-container .page-content {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    background: #fff;
    padding: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    overflow-y: auto; }
    .page-container .page-content:first-child {
      margin-top: 0px; }
    .page-container .page-content .page-table {
      overflow-y: auto;
      height: 100%;
      margin-bottom: 0px; }
    .page-container .page-content .page-pagetable {
      height: calc(100% - 40px);
      width: 100%;
      margin-bottom: 0px; }
    .page-container .page-content .page-pager {
      height: 40px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }

/* 内容区域左侧树 */
.main-left-tree-wrapper {
  height: 100%;
  background: #fff; }
  .main-left-tree-wrapper .main-left-tree-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 5px 10px;
    background: var(--mainThemeColor);
    color: #fff; }
    .main-left-tree-wrapper .main-left-tree-header i {
      margin-right: 14px;
      cursor: pointer; }
      .main-left-tree-wrapper .main-left-tree-header i:last-of-type {
        margin-right: 0px; }
  .main-left-tree-wrapper .main-left-tree-content {
    width: 100%;
    height: calc(100% - 42px); }
    .main-left-tree-wrapper .main-left-tree-content .main-left-tree-divider span {
      color: var(--mainThemeColor);
      font-size: var(--rootFontSize); }
    .main-left-tree-wrapper .main-left-tree-content .main-left-tree-filter {
      padding: 0px 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }
    .main-left-tree-wrapper .main-left-tree-content .main-left-tree-instance {
      height: calc(100% - 20px);
      overflow-y: auto;
      padding: 10px; }
      .main-left-tree-wrapper .main-left-tree-content .main-left-tree-instance .custom-tree-node {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between; }
        .main-left-tree-wrapper .main-left-tree-content .main-left-tree-instance .custom-tree-node .custom-tree-node-btn {
          opacity: 0.5;
          filter: alpha(opacity=60);
          /* 针对 IE8 以及更早的版本 */ }
        .main-left-tree-wrapper .main-left-tree-content .main-left-tree-instance .custom-tree-node .custom-tree-node-btn:hover {
          opacity: 1;
          filter: alpha(opacity=200);
          /* 针对 IE8 以及更早的版本 */ }

/* 模块标题样式 */
.text_box {
  width: 100%;
  height: 30px;
  font-size: var(--rootFontSize);
  font-weight: 600; }
  .text_box span {
    color: var(--mainThemeColor) !important; }
  .text_box .el-button span {
    color: #ffffff !important; }

.text_box_line {
  width: 100%;
  height: 2px;
  margin-top: 5px;
  margin-bottom: 10px;
  background-color: #7f7f7f; }

.clearfix {
  width: 100%;
  height: 0;
  display: table;
  clear: both; }

.notice-style {
  background: #e3f2fd;
  cursor: pointer; }
  .notice-style .notice-hover:hover {
    color: #409eff; }
  .notice-style .notice-content-date {
    min-width: 280px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
